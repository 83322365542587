<template>
    <div class="main-page">
        <div class="container">
            <div class="row">
                <div class="col-md-5 col-md-push-7 vh-100 pe-5">
                    <div class="form-personal-data w-100 pe-5 pb-3">
                        <div class="group-title mb-5">
                            <p class="text-active fm-poppins fst-normal fw-600 fs-18 lh-27 mb-0">Data Diri</p>
                            <p class="text-body-light fm-nunito fst-normal fw-normal fs-16 lh-22 mb-0">Untuk memudahkan anda melakukan pemeriksaan silahkan lengkapi data diri anda.</p>
                        </div>
                        <form>
                            <label class="form-label text-body fm-nunito fst-normal fw-600 fs-14 lh-19">No. KTP</label>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control bg-grayscale text-placeholder fm-nunito fst-normal fw-600 fs-12 lh-16 p-3 border-0" placeholder="No. KTP">
                            </div>
                            <label class="form-label text-body fm-nunito fst-normal fw-600 fs-14 lh-19">Tanggal Lahir</label>
                            <div class="input-group mb-3">
                                <input type="date" class="form-control bg-grayscale text-placeholder fm-nunito fst-normal fw-600 fs-12 lh-16 p-3 border-0" placeholder="" onfocus="(this.type='date')">
                            </div>
                            <label class="form-label text-body fm-nunito fst-normal fw-bold fs-14 lh-19">No. Telepon</label>
                            <div class="input-group mb-3">
                                <input type="text" class="form-control bg-grayscale text-placeholder fm-nunito fst-normal fw-600 fs-12 lh-16 p-3 border-0" placeholder="+62">
                            </div>
                            <label class="form-label text-body fm-nunito fst-normal fw-bold fs-14 lh-19">Alamat</label>
                            <div class="input-group mb-3">
                                <textarea class="form-control bg-grayscale text-placeholder fm-nunito fst-normal fw-400 fs-16 lh-22 px-3 py-3 border-0" placeholder="Masukan Alamat..."></textarea>
                            </div>
                            <label class="form-label text-body fm-nunito fst-normal fw-bold fs-14 lh-19">Kota</label>
                            <div class="input-group mb-3">
                                <select id="inputState" class="form-select form-control bg-grayscale text-placeholder fm-nunito fst-normal fw-600 fs-12 lh-16 p-3 border-0">
                                    <option selected>Pilih Kota</option>
                                    <option>...</option>
                                </select>
                            </div>
                            <label class="form-label text-body fm-nunito fst-normal fw-bold fs-14 lh-19">Jenis Kelamin</label>
                            <div class="d-flex justify-content-between mb-4">
                                <div class="bd-highlight w-50">
                                    <div class="form-check pe-2">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                                        <div class="btn btn-gender active ps-3 pe-2 py-2 d-flex justify-content-between align-items-center">
                                            <p class="fm-nunito fst-normal fw-600 fs-12 lh-16 mb-0 pt-1">Laki-laki</p>
                                            <div class="box-icon active">
                                                <i class="ri-men-line icon-gender"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="bd-highlight w-50">
                                     <div class="form-check ps-2 d-flex justify-content-end">
                                       <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
                                        <div class="btn btn-gender ps-3 pe-2 py-2 d-flex justify-content-between align-items-center">
                                            <p class="fm-nunito fst-normal fw-600 fs-12 lh-16 mb-0 pt-1">Perempuan</p>
                                            <div class="box-icon">
                                                <i class="ri-women-line icon-gender"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-next w-100 py-3">
                                <p class="text-white fm-poppins fst-normal fw-600 fs-14 lh-21 mb-0">Lanjutkan</p>
                            </button>
                        </form>
                    </div>
                </div>
                <div class="col-md-7 order-first order-md-last">
                    <div class="frame-image d-flex align-items-center">
                        <div class="ps-5">
                            <img src="@/assets/illustration.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .form-personal-data {
        padding-top: 25%;
    }

    .form-control, .input-group-text {
        border-radius: 8px;
    }

    .btn-next {
        background: #007A98;
        border-radius: 10px;
    }

    .btn-gender {
        border-radius: 8px;
        background: #EFF0F7;
        color: #4E4B66;
        width: 100%;
    }

    .btn-gender.active {
        background: #FF9D0A;
        color: #FFFFFF
    }

    .frame-image {
        position: absolute;
        background: #007a9826;
        width: 56%;
        height: 57rem;
    }

    .frame-image img {
        width: 90%;
        height: auto;
    }

    .box-icon {
        padding: 2px;
        background: #ffffff;
        border-radius: 8px;
        padding-right: 8px;
        padding-left: 8px;
    }

    .box-icon.active {
        background: rgba(255, 255, 255, 0.35);
    }

    .icon-gender {
        width: 5px;
    }

    .form-check {
        padding-left: 0px;
    }

    input[type="radio"] {
        opacity: 0;
        padding: 0px;
    }

    input[type="radio"]:checked {
        /*  */
    }

    @media (min-width: 360px) and (max-width: 767px) {
        .col-md-5 {
            padding-right: 0px !important;
        }

        .form-login {
            padding-right: 1rem !important;
        }

        .col-md-7 {
            padding-left: 0px;
        }
        .frame-image {
            position: relative;
            background: #007a9826;
            width: 100%;
        }
    }
</style>
